import Input from '@atoms/new/input';
import { Menu, MenuItem } from '@nextui-org/menu';
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/popover';
import { useDisclosure } from '@nextui-org/react';
import { useState } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface SearchableDropdownProps {
  selected: string;
  options: string[];
  trigger?: React.ReactNode;
  onChange?: (value: string) => void;
  isLoading?: boolean;
}

export const SearchableDropdown = ({
  options = [],
  selected = '',
  trigger = <></>,
  onChange = () => {},
  isLoading = false
}: SearchableDropdownProps) => {
  const [query, setQuery] = useState('');
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(query.toLowerCase())
  );

  const { isOpen, onOpenChange, onClose } = useDisclosure();

  const handleChange = (value: string) => {
    setQuery('');
    onChange(value);
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      disableAnimation
      placement="bottom-start"
      classNames={{
        base: 'w-[240px]',
        trigger:
          'aria-expanded:scale-1 aria-expanded:opacity-100 font-semibold text-black',
      }}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>

      <PopoverContent className="px-0 rounded-md bg-white">
        <Input
          value={query}
          size='sm'
          className="px-1 pb-1"
          placeholder="Type anything or pick one"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && query.length !== 0) {
              handleChange(query);
            }
          }}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Menu
          classNames={{
            base: '!p-0 max-h-80 overflow-scroll scrollbar-hide',
            list: 'gap-0',
          }}
          disabledKeys={['loading']}
          selectedKeys={[selected]}
          selectionMode="single"
        >
          {isLoading && (
            <MenuItem key="loading" className="rounded-none py-2 px-4 text-grey-900">
              Loading...
            </MenuItem>

          )}
          {
            filteredOptions.sort(option => option === selected ? -1 : 1).map((option) => (
              <MenuItem
                selectedIcon={({ isSelected }) => {
                  return (
                    <CheckRoundedIcon
                      fontSize="small"
                      className={`${isSelected ? '' : 'invisible'}`}
                    />
                  );
                }}
                className="rounded-none items-start py-1.5 px-4 text-grey-900 data-[hover=true]:!bg-grey-100 data-[selected=true]:bg-grey-100 data-[selectable=true]:focus:bg-grey-200 data-[selectable=true]:focus:text-grey-900"
                key={option}
                onClick={() => handleChange(option)}
              >
                {option}
              </MenuItem>
            )) as any
          }

          {filteredOptions.length === 0 && query.length !== 0 && (
            <MenuItem
              selectedIcon={({ isSelected }) => {
                return (
                  <CheckRoundedIcon
                    fontSize="small"
                    className={`${isSelected ? '' : 'invisible'}`}
                  />
                );
              }}
              className="rounded-none py-2 px-4 text-grey-900 data-[hover=true]:!bg-grey-100 data-[selected=true]:bg-grey-100 data-[selectable=true]:focus:bg-grey-200"
              key={query}
              onClick={() => handleChange(query)}
            >
              <p className="text-grey-700 font-normal text-sm flex gap-1">
                <span>Use</span>
                <span className="text-grey-900">
                  "{query.length > 10 ? `${query.slice(0, 10)} ...` : query}"
                </span>
              </p>
            </MenuItem>
          )}
        </Menu>
      </PopoverContent>
    </Popover>
  );
};
