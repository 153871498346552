/* eslint-disable import/prefer-default-export */
import { postWithAuth } from './http.service';
import { UrlParamsReplace } from './url.service';

const POST_ONBOARDING_URL = () => UrlParamsReplace('onboarding');
const POST_ONBOARDING_DONE_URL = () => UrlParamsReplace('onboarding/done');

export const postOnboarding = (data: any) =>
  postWithAuth(POST_ONBOARDING_URL(), data);

export const postOnboardingDone = () =>
  postWithAuth(POST_ONBOARDING_DONE_URL());
