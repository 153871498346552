import Button from '@atoms/new/button';

interface GenAiResultFooterProps {
  selectedBlocks: number;
  onAddToSlide: () => void;
}

const getButtonText = (count: number) => {
  if (count === 0) return 'Add slides';
  if (count === 1) return 'Add slide';
  return `Add ${count} slides`;
};

export const GenAiResultFooter = ({
  selectedBlocks,
  onAddToSlide,
}: GenAiResultFooterProps) => {
  return (
    <div className={'select-none flex items-center w-full justify-end'}>
      <Button isDisabled={selectedBlocks === 0} onClick={onAddToSlide}>
        {getButtonText(selectedBlocks)}
      </Button>
    </div>
  );
};
