export function numberToAlphabet(num: number) {
  // Check if the number is within the range of 1 to 26
  if (num >= 1 && num <= 26) {
    // Convert the number to its corresponding ASCII code for uppercase letters
    return String.fromCharCode(num + 64); // A is 65 in ASCII, so we add 64 to get the correct character
  } else {
    // If the number is out of range, return an error message
    return 'Number out of range (1-26)';
  }
}
