import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ROS_PREVIEW_SESSION = 'ROS_PREVIEW_SESSION';

export const getRosPreviewSession = () => cookies.get(ROS_PREVIEW_SESSION);

export const setRosPreviewSession = (value = 'userId:templateId', attributes = { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN }) => {
  cookies.set(ROS_PREVIEW_SESSION, value, attributes);
};
