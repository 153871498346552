import { Chip, type ChipProps } from '@nextui-org/chip';

interface TagProps extends ChipProps {
  isHoverable?: boolean;
  kind?: 'default' | 'info' | 'success' | 'warning' | 'error';
}

const Tag = ({
  size = 'md',
  kind = 'default',
  isHoverable = false,
  ...rest
}: TagProps) => {
  const kinds = {
    default: 'bg-secondary-900 text-grey-800',
    info: 'bg-blue-50 text-blue-600',
    success: 'bg-green-50 text-green-600',
    warning: 'bg-pure-orange-50 text-pure-orange-600',
    error: 'bg-pure-red-50 text-pure-red-600',
  }[kind];

  const sizes = {
    sm: 'text-xs py-0.5 px-2 h-5',
    md: 'text-sm py-1 px-2 h-7',
    lg: 'text-base p-2 h-10',
  }[size];

  let hoverable = '';

  if (isHoverable) {
    hoverable += 'cursor-pointer';
    const effectOnHover = {
      default: 'bg-grey-200',
      info: 'bg-blue-100',
      success: 'bg-green-100',
      warning: 'bg-pure-orange-100',
      error: 'bg-pure-red-100',
    }[kind];

    hoverable += ` hover:${effectOnHover}`;
  }

  return (
    <Chip
      {...rest}
      classNames={{
        base: 'gap-1 select-none',
        content: 'font-medium px-0',
      }}
      className={` ${kinds} ${hoverable} ${sizes} ${rest.className}`}
    />
  );
};

export default Tag;
